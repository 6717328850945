#PhotoGallery {
  height: 100%;
}

.swiper {
  width: 80vw;
  height: 80vh;
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 2vh; /* Location of the box */
  left: 10vw;
  top: 5vh;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
}

.GalleryIcon:hover {
    cursor: pointer;
    border-style:dotted;
    border-width:1px;
}

.swiper-slide {
  text-align: center;
}

.swiper-slide img {
  display: block;
  opacity: 1;
  width: 100%;
  height: 100%;
  object-fit:scale-down;
}

.GalleryCaption{

  bottom: 3%;
  color: darkgrey;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%; /* Need a specific value to work */
  border-width: 1px;
  font-size: 3vw;

}
/* The Close Button */
.close {
 position: absolute;
 top: 15px;
 right: 35px;
 color: #f1f1f1;
 font-size: 6vh;
 font-weight: bold;
 transition: 0.3s;
}

.close:hover,
.close:focus {
 color: #bbb;
 text-decoration: none;
 cursor: pointer;
}

.App {
  background-color: #f4e1be;
  text-align: center;
  max-width:1400px;
  margin:auto;

  height:100vh;

}

.HomeView{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  
}

.BannerImage{
  border-radius:1%;
  border-style:ridge;
  border-width: 2px;

  border-right-color:#005838;
  border-bottom-color:#005838;
  
  
  background-color: #f4e1b1;
  padding:2px;
  
 
  width: 95%;
  max-width:1300px;
  max-height: 250px;
    
    
 }

.HomeViewImage{
 
  width: 100vw;
  margin-top: -2vh;
  margin-left:-2vw;

}


.HomeText{
  max-height:25vh;
  display: flexbox;
 
  overflow: hidden;
  font-size: 95%


}

body {
  height:100%;
  overflow: hidden;
  font-family: 'Arial', Courier, monospace;
  font-size: large;
  color:#695738;

}

.AppLogo{max-width:1398px;
  max-height: 35vh;
  width: 99vw;
  pointer-events: none;
}

.ContentView{
  display: flex;
  flex-direction:row;
}

@media (prefers-reduced-motion: no-preference) {
  .AppLogo{
    animation: App-logo-spin 1 2s linear;
  }
  .TopBarImage {
    animation: App-logo-spin 1 2s linear;
  }
}

.TopBarImage{
  width: 100vw;
  pointer-events: none;

}

.content-Top-Padding{
  min-height: 3vh;
}

.ContentText{
  display:flex;
  height:100%;
  justify-content: center;
  align-items: center;  
}


.ContentDescription{  
  
  font-weight:600;
  display:flex;
  max-width: 800px;
  min-height: 30vh;
  max-height: 70vh;


}

.GalleryTitle{


  font-size:large;
  font-weight: bold;
  color:#6a5147;
  margin-bottom:2vh;
  padding-top:2vh;
}

.ContentBorder{

  border-radius:1%;
  border-style:ridge;
  border-width: 2px;

  border-right-color:#005838;
  border-bottom-color:#005838;
  background-color: #f4e1b1;
  padding:10px;

}
.ContentIcon{
  display:flex;
  align-items: center;
  max-width: 15vw;
  justify-content: center;
  flex-direction:column;
  
  margin-left:5vw;
  margin-right:2vw;
  padding-left: 3vw;
  padding-right: 3vw;


}

.GalleryLink{
  color:blue;
  text-align: center;

}

.GalleryLink:hover {
    cursor: pointer;
    border-bottom-width:1px;
    border-bottom-style:dotted;
}

.GalleryIcon:hover {
    cursor: pointer;
    border-style:dotted;
    border-width:1px;
}

.GalleryIcon{
  max-width: 95%;
}

.ContentBody{
  padding-left: 2vw;
  max-width:1400px;
  min-height: 30vh;
  vertical-align:middle;
  border-left-style:solid;
  border-right-style:solid;
  border-width: 1px;
}

.NavBarContainer{
    color: #005838;
    display: flex;
    flex-direction: row;
    justify-content: normal;
    border-top-style:solid;
    border-top-width:2px;
    border-color:#6a5147;
    max-width:1400px;
}

.NavLink{
  margin-left: 1vh;
  margin-right: 1vh;
  min-width:min(16vw, 260px);
  
  border-bottom-width:2px;
  border-top-style:solid;
  border-top-color:#f4e1be;
  

  font-size: min(3vw, 36px);
  letter-spacing:2px;

}

.NavLink:hover {
    cursor: pointer;
    border-bottom-width:2px;
    border-top-style:solid;
    border-top-color:#005838;
}

.TopBar{
  font-family: "Crimson Text", serif;
   font-weight: 600;
   font-size:4vw;
   letter-spacing:5px;
   font-style: normal;
   color:#6a5147;
   min-height: 10vh;
   max-width:1400px;
   border-left-style:solid;
   border-right-style:solid;
   border-width: 1px;

}


.Footer{
  min-height: 15vh;
  border-left-style:solid;
  border-right-style:solid;
  border-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color:#6a5147;
}

@keyframes App-logo-spin {
  from {
    transform: skew(180deg,0deg);
  }
  to {
    transform: skew(360deg,180deg);
  }

}
